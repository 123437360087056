html,
body,
table,
mat-label {
	font-weight: 300;
}

.font-heading {
	font-family: var(--font-heading);
	font-weight: 700;
}

.font-paragraph {
	font-family: var(--font-paragraph);
	font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-heading);
	font-weight: 700;
}

h1 {
	font-size: 3rem;
	line-height: 3rem;
}

h2 {
	font-size: 2.5rem;
	line-height: 2.5rem;
}

h3 {
	font-size: 2rem;
	line-height: 2rem;
}

h4 {
	font-size: 1.5rem;
	line-height: 1.5rem;
}

h5 {
	font-size: 1rem;
	line-height: 1rem;
}

a {
	color: inherit;
	text-decoration: none;

	&.underline {
		text-decoration: underline;
	}

	&.underline-hover {
		&:hover {
			text-decoration: underline;
		}
	}
}

strong,
.strong {
	font-weight: 600;
}

// Font sizes
.font-size-xs {
	font-size: 11px;
}

.font-size-small {
	font-size: 1.2rem;
}

.font-size-medium {
	font-size: 1.4rem;
}

.font-size-default {
	font-size: 1.6rem;
}

.font-size-large {
	font-size: 1.8rem;
}

.font-size-xl {
	font-size: 2.2rem;
}

.font-size-xxl {
	font-size: 2.6rem;
}

// Text Color
.text-color-primary {
	color: var(--color-primary);
}

.text-color-danger {
	color: #ff0159;
}

.text-color-success {
	color: #00d185;
}
