main {
	padding: 30px 30px 100px 90px;
	max-width: $max-width;
	min-height: calc(100% - 114px);
	position: relative;
	min-height: 100vh;
	overflow-x: auto;

	&.stretch {
		max-width: none;
	}

	&.skinny {
		max-width: 800px;
	}

	&.no-padding-bottom {
		padding-bottom: 0;
	}

	&.same-padding-bottom {
		padding-bottom: 30px;
	}
}

section {
	border-radius: 5px;

	// HACK: Get Safari to recognize the border-radius
	transform: translateZ(0);

	&.white {
		background-color: white;
		padding: 20px 25px;
	}

	&.header {
		margin-bottom: 15px;
		padding-top: 0;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 0;
		}

		flex-wrap: wrap;
		gap: 12px;

		.group {
			display: flex;
			flex-wrap: wrap;
			grid-row-gap: 12px;
		}
	}
}

.title-row {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0 0 5px;
	}

	h3 {
		font-size: 1.8rem;
	}

	.instruction {
		font-size: 1.2rem;
		color: var(--color-step-350);
		font-weight: 300;
	}

	.right {
		margin-left: auto;
	}

	@media only screen and (max-width: 768px) {
		flex-direction: column;
		grid-row-gap: 24px;
	}
}

button.mat-mini-fab .mat-button-wrapper {
	padding: 0;
}

.content-row {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	padding: 5px 0;
	width: 100%;

	.right {
		margin-left: auto;
	}

	.button-group {
		button.mat-flat-button.mat-primary,
		button.mat-mini-fab.mat-primary,
		button.mat-fab.mat-primary {
			background: white;
			color: var(--color-secondary);
			// border: 1px solid var(--color-primary);

			&.active {
				background: var(--color-primary);
				color: white;
			}
		}

		button.mat-flat-button.mat-secondary,
		button.mat-mini-fab.mat-secondary,
		button.mat-fab.mat-secondary {
			background: white;
			color: var(--color-secondary);
			// border: 1px solid var(--color-secondary);

			&.active {
				background: var(--color-secondary);
				color: white;
			}
		}
	}
}

.filter-row {
	align-items: center;
	margin-bottom: 5px;
	overflow: hidden;

	.left,
	.right {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		> * {
			margin-right: 5px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
	.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
		color: var(--color-secondary);
	}

	button.mat-flat-button,
	button.mat-mini-fab,
	button.mat-fab {
		background: var(--color-step-50);
		color: var(--color-secondary);

		&:hover {
			background: var(--color-secondary);
			color: var(--color-step-50);
		}

		&.active {
			background: var(--color-secondary);
			color: white;
		}

		&.primary {
			background: var(--color-primary);
			color: white;
		}

		.mat-icon {
			font-size: 20px;
		}
	}

	button.mat-flat-button.mat-tertiary:hover,
	button.mat-mini-fab.mat-tertiary:hover,
	button.mat-fab.mat-tertiary:hover {
		background-color: #c3c4c6;
	}

	&.on-white {
		button.mat-flat-button.mat-primary,
		button.mat-mini-fab.mat-primary,
		button.mat-fab.mat-primary {
			background: var(--color-secondary-step-25);
			border: 1px solid var(--color-primary);
		}

		button.mat-flat-button.mat-secondary,
		button.mat-mini-fab.mat-secondary,
		button.mat-fab.mat-secondary {
			background: var(--color-secondary-step-25);
			border: 1px solid var(--color-secondary);
		}
	}
}

.border-radius-wrapper {
	border-radius: 4px;
	overflow: hidden;
}

.back-container {
	display: flex;
	align-items: center;
	margin-bottom: 4px;

	a {
		display: flex;
		align-items: center;
		opacity: 0.5;
		cursor: pointer;
		font-weight: 500;

		mat-icon {
			font-size: 1.3rem;
			height: 15px;
			width: 16px;
		}

		span {
			font-size: 1.4rem;
		}
	}
}

.metadata {
	padding: 0;

	ul {
		display: flex;
		flex-wrap: wrap;
		row-gap: 5px;

		li {
			margin-right: 20px;
			font-size: 1.3rem;
			align-items: center;

			.cat {
				margin-right: 8px;
				color: var(--color-step-500);
			}

			.value {
				font-weight: 500;
			}
		}
	}

	.status {
		min-height: 19px !important;
	}
}

section.tabs {
	margin: 20px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 0;

	mat-tab-header {
		font-size: 1.4rem;
	}

	mat-tab-body {
		display: none;
	}

	.mat-secondary .mat-tab-label {
		border-bottom: 2px solid rgba(0, 0, 0, 0);
	}

	mat-tab-group {
		&.underline {
			.mat-tab-header,
			.mat-tab-nav-bar {
				border-bottom: 1px solid var(--color-step-100);
			}

			.mat-tab-label {
				border-bottom: none;
			}
		}
	}
}

.entities-container {
	width: 100%;
	margin: 20px 0;

	.entity {
		background: var(--color-step-25);
		padding: 10px 20px;
		border-radius: 4px;
		border: 1px solid var(--color-step-200);

		.mat-form-field-wrapper {
			margin-bottom: -1.25em;
		}

		.details {
			flex: 1;
			cursor: pointer;

			.name-container {
				display: flex;
				align-items: center;
				margin-bottom: 5px;

				.name {
					font-weight: bold;
					font-size: 17px;
					line-height: 17px;
					margin: 0 10px 0 0;
				}

				.status {
					// HACK: Stupid font line heights mean we have to move this up to center
					margin-top: -4px;
				}
			}

			.metadata ul li .value {
				font-weight: 300;
			}

			@media only screen and (max-width: 960px) {
				width: 100%;
			}
		}

		.actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.btn {
				padding: 15px;
				cursor: pointer;
				display: flex;

				&:hover {
					opacity: 0.6;
				}

				&:last-child {
					padding-right: 0;
				}
			}

			&.inline {
				padding-top: 15px;

				button {
					margin-right: 8px;
				}

				button.mat-flat-button.button-extra-small.icon-left {
					.mat-button-wrapper {
						padding-left: 8px;
						margin-left: 0;
					}
				}
			}
		}

		.cost {
			text-align: right;

			.title {
				font-family: var(--font-paragraph);
				color: var(--color-step-400);
				font-size: 1.4rem;
				font-weight: 300;
				margin-bottom: 0.1em;
			}

			.price {
				font-weight: bold;
				margin: 0;
				line-height: 18px;
			}

			&.incomplete {
				.title {
					// Hack to get this font to center vertically
					padding-top: 2px;
				}
			}
		}

		&.draft {
			border-style: dashed;
			background: white;
		}

		&.flex-entity {
			display: flex;
			align-items: center;
			padding: 16px 20px;
			transition: border 300ms;

			&:hover {
				border: 1px solid var(--color-secondary);
			}

			&.no-hover:hover {
				border: 1px solid var(--color-step-200);
			}

			@media only screen and (max-width: 960px) {
				flex-direction: column;
				gap: 10px;
				align-items: flex-start;
			}
		}

		&.column-flex-entity {
			display: flex;
			align-items: flex-start;
			padding: 16px 20px;
			gap: 10px;
			transition: border 300ms;
			flex-direction: column;

			&:hover {
				border: 1px solid var(--color-secondary);
			}

			&.no-hover:hover {
				border: 1px solid var(--color-step-200);
			}
		}

		& + .drawer {
			width: 95%;
			border: 1px solid var(--color-step-100);
			margin: 0 auto;
			display: block;
			background: #efefef;
			padding: 0;
			background: var(--color-step-50);
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			overflow: hidden;
			padding: 15px;

			section.tabs {
				margin-top: 0;
			}
		}
	}
}

.get-started-container {
	background: #e8e8e8;
	border-radius: 6px;
	width: 100%;
	//height: 100%;
	padding: 70px 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.content-container {
		position: relative;
	}

	.title {
		font-size: 1.8rem;
		line-height: 1.8rem;
		font-weight: bold;
	}

	.description {
		margin-bottom: 20px;
	}

	.actions {
		& > * {
			margin: 8px auto;
			display: block;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.short {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.button-list {
	width: 100%;

	a {
		display: block;
		width: 100%;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		border: 1px solid rgba(0, 0, 0, 0.2);
		background: white;
		padding: 5px 8px;
		border-radius: 4px;
		font-weight: 400;
		font-size: 1.7rem;
		line-height: 1.7rem;
		margin-bottom: 8px;
		cursor: pointer;
		transition: all 300ms;

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		i {
			color: var(--color-primary);
		}

		.uil-angle-right-b {
			margin-left: auto;
		}

		&:hover {
			background: var(--color-step-50);
		}
	}

	&.clean {
		a {
			border: none;
			font-size: 1.3rem;
			color: var(--color-primary);

			i {
				color: var(--color-primary);
				font-size: 1.7rem;
			}

			.uil-angle-right-b {
				margin-left: 5px;
			}
		}
	}
}

.summary-row {
	.left {
		flex: 1;
	}

	.title {
		font-size: 1.3rem;
		color: var(--color-primary);

		a {
			color: var(--color-primary);
			font-weight: 400;

			&:hover {
				color: var(--color-secondary);
			}
		}
	}

	.subtitle {
		font-size: 1.1rem;
		font-weight: 300;
	}

	.right {
		font-size: 1.1rem;
		text-align: right;
	}

	.tag {
		background-color: var(--color-step-150);
		padding: 3px 4px;
		color: white;
		display: inline;
		text-transform: capitalize;
	}
}

.selection-grid {
	display: flex;
	flex-wrap: wrap;

	.item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 5px;
		min-width: 185px;
		min-height: 100px;
		background: var(--color-step-100);
		margin-right: 5px;
		cursor: pointer;
		transition: all 300ms;

		i {
			font-size: 3rem;
		}

		.title {
			font-size: 1.2rem;
		}

		&.full-width {
			width: 100%;
		}

		&:hover {
			background: var(--color-step-200);
		}

		&.active {
			border: 3px solid var(--color-secondary);
		}
	}
}

.scoreboard {
	border: 1px solid var(--color-secondary-step-800);
	border-radius: 5px;
	padding: 20px 14px;
	margin-right: 10px;
	margin-bottom: 10px;
	min-width: 160px;
	display: flex;
	flex-direction: column;

	.title {
		font-size: 1.6rem;
		margin: 0;
		line-height: 1.6rem;
		margin-bottom: 5px;
	}

	.value {
		font-size: 2.7rem;
		line-height: 2.7rem;
		margin: 0 0 4px;
	}

	.subtitle {
		font-size: 1.1rem;
		line-height: 1.1rem;
		opacity: 0.5;
		margin: 0;
	}
}

.separator {
	width: 100%;
	height: 1px;
	background-color: #e6e6e6;
}

.load-more-notification {
	position: absolute;
	bottom: 25px;
	z-index: 100;
	background: var(--color-secondary);
	color: white;
	padding: 10px 20px;
	left: 50%;
	transform: translateX(-50%);
}
