form {
	width: 100%;
}

.input-group {
	display: flex;
	width: 100%;
	padding-top: 3px;
	padding-bottom: 3px;
	min-width: 100%;
	width: 100%;
	flex-direction: row;
	border-bottom: 1px solid #f7f7f7;

	@media only screen and (max-width: 992px) {
		border-bottom: none;
	}

	&:last-child {
		border-bottom: none;
	}

	.input {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}

		&.stretch-height {
			.mat-form-field-wrapper {
				min-height: calc(100% - 2.6em);
			}
		}

		textarea {
			width: 100%;
			line-height: 2rem;
		}

		mat-slide-toggle {
			margin: 22px 0px;
		}

		.mat-hint {
			color: var(--color-step-400);
			font-weight: 300;
		}
	}

	&.with-hint {
		margin-bottom: 35px;
	}
}

mat-select {
	.mat-select-placeholder {
		font-weight: 300;
		color: var(--color-step-200);
	}
}

mat-date-range-input {
	input::placeholder,
	.mat-date-range-input-separator {
		font-weight: 300;
	}
}

.mat-chip-list-wrapper input.mat-input-element {
	font-weight: 300;
}

mat-optgroup.condensed-options {
	.mat-optgroup-label,
	.mat-option {
		font-size: inherit !important;
		line-height: 2.2em !important;
		height: 2.2em !important;
	}
}

.mat-select-search-inside-mat-option .mat-select-search-input,
.mat-select-search-no-entries-found {
	color: var(--color-step-600);
}

.mat-select-search-inside-mat-option .mat-select-search-clear {
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mat-form-field {
	font-weight: 300;

	&.no-hint {
		.mat-form-field-wrapper {
			margin-bottom: -1.25em;
		}
	}
}

.readonly {
	.mat-form-field-disabled .mat-form-field-label {
		color: var(--color-primary) !important;
	}

	.mat-select-arrow-wrapper {
		opacity: 0;
	}

	.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline,
	.mat-form-field-appearance-legacy .mat-form-field-underline {
		background-color: transparent !important;
	}
}

.mat-datepicker-content .mat-calendar-content .mat-calendar-body-selected {
	color: white !important;
}

.required-danger-marker {
	color: var(--color-danger);
}
.mat-form-field.mat-required-danger-marker .mat-form-field-label > .mat-form-field-required-marker {
	color: var(--color-danger);
}
