main.details-page {
	display: flex;
	flex-direction: column;
	padding: 0;
	position: absolute;
	top: 0;
	left: 60px;
	right: 0;
	bottom: 0px;
	overflow: hidden;

	.header {
		align-items: flex-start;
	}

	.header-container {
		padding: 20px 30px 0;
		background-color: white;
	}

	.back {
		margin-right: 10px;
		color: var(--color-primary);
		display: flex;
		align-items: center;
		margin-bottom: -5px;

		i {
			font-size: 2.5rem;
			line-height: 2.5rem;
		}
	}

	app-metadata-bar {
		padding-left: 34px;
		display: block;

		.metadata {
			margin-bottom: 0;
			padding-bottom: 5px;
		}
	}

	.tabs {
		padding: 0 30px 0;
		margin: 0;
		background-color: white;
	}

	.tab-content {
		// flex: 1;
		position: relative;
		// display: flex;
		// height: calc(100vh - 180px);
		overflow: visible;

		&.no-panels {
			padding: 30px;
			width: auto;
			flex-direction: column;
			overflow: auto;
		}
	}

	app-panel-container {
		app-panel-sidebar,
		.panel-content-container {
			overflow: auto;
		}
	}
}
// Set fullscreen mode
main.details-page-fullscreen {
	left: 0px;
}
